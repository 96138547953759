<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="financiera" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords}">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Descuentos</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['nombredoctor']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<Column field="nombredoctor" header="Doctor" :sortable="true"></Column>
					<Column field="id_muestra" header="Muestra" :sortable="true"></Column>
					<Column field="pacienteN" header="Paciente" :sortable="true"></Column>
					<Column field="monto" header="Monto" :sortable="true">
						<template #body="slotProps">
							<div :class="preciostyle" style="text-align: right">
								${{formatCurrency(slotProps.data.monto)}}
							</div>
						</template>
					</Column>
					<Column field="porcentajedescuento" header="Porcentaje descuento aplicado" :sortable="true">
						<template #body="slotProps">
							<div :class="preciostyle" style="text-align: right">
								{{slotProps.data.porc_descuento > 0 && slotProps.data.usuarioautoriza ? slotProps.data.porc_descuento + '%': ''}}
							</div>
						</template>
					</Column>
					<Column field="descuento" header="Descuento aplicado" :sortable="true">
						<template #body="slotProps">
							<div :class="preciostyle" style="text-align: right">
								{{slotProps.data.descuento > 0 ? '$' + formatCurrency(slotProps.data.descuento) : ''}}
							</div>
						</template>
					</Column>
					<Column field="montofinal" header="Monto final" :sortable="true">
						<template #body="slotProps">
							<div :class="preciostyle" style="text-align: right">
								${{formatCurrency(slotProps.data.monto - slotProps.data.descuento)}}
							</div>
						</template>
					</Column>
					<Column field="nombredoctordescuento" header="Doctor solicita descuento" :sortable="true"></Column>
					<Column field="usuarioautoriza" header="Usuario autoriza descuento" :sortable="true"></Column>
				</DataTable>

			
			</div>
		</div>
	</div>

</template>

<script>
import SituacionFinanciera from '../service/SituacionFinanciera';
import XLSX from 'xlsx'
import Cookies from "js-cookie"
window.Cookies = Cookies
 import moment from 'moment';

export default {
	data() {
		return {			
			ConsultaDialog: false,
			ExportExcel:false,
			deletePaisDialog: false,
			deletePaisesDialog: false,
			filters: {},
			submitted: false,
			visibleT:true,			 
			financiera:null,
		 	Totals:0,
			dataConsulta:null,
			ServiciosSelect:null,
			ServiciosM:null, 
			Fecha:null,
			Monto:null,
			MetodoPago:null,
			Banco:null,
			PagoPost:[],
			useconver:null,
			userdata:null,
			PagosMuestra:null,
			FechaFin:null,
			FechaInicio:null,
			PostExport:[],
			FinancieraExpor:[],
			deleteDialog:false,
			TotalN:0,
			detallepago:null,
			UpdEnviarPago:[],
			PagoFinan:null,
			SaldoExcel:null,
			MontoExcel:null,
			PrecioExcel:null,
			IdMuestraExce:null,
			Active:null,
		}
	},
	situacionfinanciera: null,
	created() {
		this.situacionfinanciera = new SituacionFinanciera();
	},
	mounted() {
		this.situacionfinanciera.getSituacionFinanciera().then(data => {
			this.financiera = data.muestras;
			this.ServiciosM = data.servicios;

			console.log(this.financiera)
			 
			for(var i in this.financiera)
			{
				
				var Nombre=this.financiera[i].paciente;
                this.financiera[i].pacienteN=Nombre+" "+data.muestras[i].pacienteApP+" "+data.muestras[i].pacienteApM;
                	
				if(this.financiera[i].id_estatus ==1 || this.financiera[i].id_estatus==2)
				{
					this.financiera[i].situacion='Al Corriente';
					for(var ir1 in data.pagoservicio)
					{
						if(this.financiera[i].id_muestra == data.pagoservicio[ir1].id_muestra)
						{
							var servtotal1= data.pagoservicio[ir1].monto;
							var descuento1 = this.financiera[i].id_usuario_autoriza ? Number(this.financiera[i].porc_descuento)/100 * servtotal1 : 0;

							var finan1=0;


							this.financiera[i].descuento = descuento1;
							this.financiera[i].monto = servtotal1;
							this.financiera[i].pago=finan1;
							this.financiera[i].saldo=servtotal1 - descuento1 - finan1;								
								for(var ij1 in data.pagofinan)
								{
									finan1=0;
									finan1=data.pagofinan[ij1].monto;
									
									if(this.financiera[i].id_muestra == data.pagofinan[ij1].id_muestra)
									{									
										this.financiera[i].pago=finan1;
										this.financiera[i].saldo=servtotal1 - descuento1 - finan1;
										
										if(servtotal1 - descuento1 == finan1)
										{
											this.financiera[i].situacion='Pagado';
										}										
																	 
									} 	
							}
						}
					}

				}
				else
				{			
					for(var ir in data.pagoservicio)
						{
							if(this.financiera[i].id_muestra == data.pagoservicio[ir].id_muestra)
							{
								var servtotal=data.pagoservicio[ir].monto;
								var descuento = this.financiera[i].id_usuario_autoriza ? Number(this.financiera[i].porc_descuento)/100 * servtotal : 0;
								console.log('descuento', data.porc_descuento ,descuento1)

								var finan = 0;

								this.financiera[i].descuento = descuento;
								this.financiera[i].monto = servtotal;
								this.financiera[i].pago=finan;
								this.financiera[i].saldo = servtotal - descuento - finan;

								if(data.pagofinan.length == 0)
								{
									this.financiera[i].pago = finan;
									this.financiera[i].saldo=servtotal - descuento - finan;	
									this.financiera[i].situacion = 'Vencido';

								}else
								{
									for(var ij in data.pagofinan)
									{
										if(this.financiera[i].id_muestra == data.pagofinan[ij].id_muestra)
										{
											finan=data.pagofinan[ij].monto;

											if(Number(servtotal) - descuento == Number(finan))
											{
												this.financiera[i].situacion='Pagado';
											}else
											{
												this.financiera[i].situacion='Vencido';
											}
										}else
										{
											this.financiera[i].situacion='Vencido';
										}
										this.financiera[i].pago = finan;
										this.financiera[i].saldo = servtotal - descuento - finan;
									}
								}
							}
						}

					}								 
				}
			});

		var today = new Date();
		 
		this.Fecha=this.computedDatesFormatNow(today);		

	},
	methods: {
		openNew() {
			this.pais = {};
			this.submitted = false;
			this.paisDialog = true;
			this.pais.activo=true;
			this.PagoPost=[];
				this.Fecha=null;
				this.MetodoPago=null;
				this.Banco=null;
				this.Monto=null;
		},
		hideDialog() {
			this.ConsultaDialog = false;
			this.submitted = false;
			this.Totals=0;
			this.dataConsulta=null;
			this.ServiciosSelect=[];
			document.location.reload();
		},
		openNewExport() {
			 
			this.ExportExcel = true;
			this.FinancieraExpor=[];
			this.FechaInicio=null;
			this.FechaFin=null;
			 
		},
		hideDialogExport() {
			this.ExportExcel = false;
			this.FinancieraExpor=[];
			 
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},		
		download : function() {
			for(var i in this.financiera){
				this.FinancieraExpor.push({
					'Doctor':this.financiera[i].nombredoctor,
					'Muestra':this.financiera[i].id_muestra,
					'Paciente':this.financiera[i].pacienteN,
					'Monto':this.financiera[i].monto,
					'Porcentaje descuento': this.financiera[i].porc_descuento,				
					'Descuento aplicado':this.financiera[i].descuento,
					'Monto final': this.financiera[i].monto - this.financiera[i].descuento,
					'Doctor descuento':this.financiera[i].nombredoctordescuento,
					'Usuario autoriza':this.financiera[i].usuarioautoriza,
				})	;	
			}

			let NameExport="Descuentos.xlsx";

			if(this.FinancieraExpor != null)
			{
				const data = XLSX.utils.json_to_sheet(this.FinancieraExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,NameExport)
			}

			this.hideDialogExport();			
		},
		 formatCurrency(value) {
            // return value.toLocaleString('en-US', {style: 'currency',currency: 'USD'});
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
		computedDatesFormatSave(value) {
                return moment(value).format('YYYY/MM/DD')
		},
		computedDatesFormatSaveExpr(value) {
                return moment(value).format('YYYY-MM-DD')
		},

		computedDatesFormatNow(value) {
                return moment(value).format('MM/DD/YYYY')
		},
		computedDateMX(value) {
                return moment(value).format('DD-MM-YYYY')
		},
		
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pais-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .pais-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pais-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
